import React from 'react';


export default function ProdutosDetails() {
  return (
    <div>

        <h1>Detalhes do  Produtos</h1>


    </div>
  );
}
