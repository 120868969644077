import React from "react";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./pages/admin";

import ProductDetails from "./pages/client/produtos/produtos.details";

// import PrivateRoute from "./services/wAuth";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/* Rotas Clients */}
        <Route path="/" exact component={Home} />
        <Route path="/produtos/:idProduto" exact component={ProductDetails} />
      </Switch>
    </BrowserRouter>
  );
}
