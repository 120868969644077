// import React from 'react';

import axios from 'axios';

const api = axios.create({

//"baseURL": "https://api.developer-zone.tk"
//api.developer-zone.tk
"baseURL": "http://localhost:5000"
// "baseURL": "https://api.developer-zone.tk"
//  "baseURL": "http://3.238.143.81:5000/"

//"baseURL": "http://34.200.251.160:5000/"
})

export default api
