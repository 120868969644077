import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://bioinformaticalabs.com/"
        target="_blank"
      >
        bioinformaticalabs.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      {/* 
      <div class="copyright">
        Developed by <a href="https://bioinformaticalabs.com/" target="_blank">Bioinformatica Labs</a>
      </div> */}
    </Typography>
  );
}
